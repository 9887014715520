<template>
	<div class="home" :style="isApp ? 'padding-top: 3.3rem' : ''">
		<div class="top" :style="isApp ? 'height: 2.9rem' : ''">
			<div class="top_search"  @click="fGoPath('searchfor')">
				<img src="~@/assets/images/new/new_001.png" alt="" class="top_icon">
				<div>请输入要搜索的大神的名称</div>
			</div>
		</div>

<!--		轮播图-->
		<template v-if="oCPData.oYCData.pageData.aImages.length > 0">
			<div class="swiper-container home_swiper">
				<div class="swiper-wrapper">
					<template v-if="oCPData.oYCData.pageData.aImages.length > 1">
						<div class="swiper-slide" v-for="(item,index) in oCPData.oYCData.pageData.aImages" :key="index">
							<img :src="item.picUrl" alt="" />
						</div>
					</template>
					<template v-if="oCPData.oYCData.pageData.aImages.length == 1">
<!--						<div class="swiper-slide">-->
							<img :src="oCPData.oYCData.pageData.aImages[0].picUrl" alt="" class="yiImg" />
<!--						</div>-->
					</template>
				</div>
			</div>
		</template>

<!--		中奖名单轮播-->
		<template v-if="oCPData.oYCData.pageData.aAnnouncement.length > 0">
			<div class="home_swipe_announcement">
				<img class="home_swipe_announcement_l" src="~@/assets/icon/icon-027.png" alt="">
				<van-swipe class="home_swipe_announcement_r" :autoplay="3000" vertical :show-indicators="false">
					<van-swipe-item v-for="(item,index) in oCPData.oYCData.pageData.aAnnouncement" :key="index" @click="toPlanDetail(item.planId)">
						<div class="home_swipe_announcement_r_li van-ellipsis">
							恭喜 <em class="app_em_a">{{item.roleName}}</em> 喜中
							<em class="app_money_a">{{item.prizeAmount}}</em> 元
						</div>
					</van-swipe-item>
				</van-swipe>
			</div>
		</template>


		<div class="dashen">
			<div class="dashen_top">
				<div class="top_left">
					<img src="~@/assets/images/new/new_003.png" alt="">
					<div class="title">大神推荐</div>
				</div>
				<div class="font" @click="toGodBd">更多</div>
			</div>
<!--			<div class="dashen_bot">-->
<!--				<div class="dashen_item" v-for="(item,index) in oCPData.godData" :key="index" @click="toZhuye(item.roleId)">-->
<!--					<div class="dashen_item_box">-->
<!--						<div class="dashen_box">-->
<!--							<img :src="item.headPicture" alt="" v-if="item.headPicture">-->
<!--							<img src="~@/assets/images/new/new_019.png" alt="" v-else>-->
<!--							<div  v-if="item.count > 0">{{item.count}}</div>-->
<!--						</div>-->

<!--					</div>-->
<!--					<div class="dashen_item_title">-->
<!--						{{item.roleName}}-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="wsj_xob" v-if="oCPData.godData.length == 0">-->
<!--					<img src="~@/assets/images/img_021.png" alt="" >-->
<!--					<div>暂无数据</div>-->
<!--				</div>-->
<!--			</div>-->
		</div>


		<div class="bangdan">
			<div class="bang_top">
				<div class="bang_title_box" style="margin-right: 0.427rem" @click="bangFun">
					<div class="bang_title" :style="oCPData.bang.styleBang1">
						每日神单
						<img v-if="!oCPData.bang.isBang" src="~@/assets/images/new/new_004.png" alt="">
					</div>
				</div>
				<div class="bang_title_box" @click="bangFun1">
					<div class="bang_title"  :style="oCPData.bang.styleBang2">
						连红榜单
						<img v-if="oCPData.bang.isBang" src="~@/assets/images/new/new_004.png" alt="">
					</div>
				</div>
			</div>
			<template v-if="!oCPData.bang.isBang">
				<div class="bang_item_box">
					<div class="bang_item" v-for="(item,index) in oCPData.godOrderData" :key="index">
						<div class="bang_item_left">
							<div class="bang_item_left_tou">
								<div class="tou">
									<img :src="item.portraitUrl" alt="" v-if="item.portraitUrl" @click="toZhuye(item.roleId)">
									<img src="~@/assets/images/new/new_019.png" alt="" v-else @click="toZhuye(item.roleId)">
									<div v-if="item.count > 0">{{item.count}}</div>
								</div>
							</div>

						</div>
						<div class="bang_item_right" @click="toPlanDetail(item.planId)">
							<div class="bang_item_left_name">{{item.roleName}}</div>
							<div class="bang_item_right_r_box">
								<div class="bang_item_right_r">
									<img src="~@/assets/images/new/ic_012.png" alt="" v-if="item.tagName == 'RICHER'">
									<img src="~@/assets/images/new/ic_014.png" alt="" v-if="item.tagName == 'HIGH_HIT'">
									<img src="~@/assets/images/new/ic_013.png" alt="" v-if="item.tagName == 'CATTLE'">
									<img src="~@/assets/images/new/ic_015.png" alt="" v-if="item.tagName == 'POP_LIST'">
									<div class="bang_item_right_title">中奖</div>
								</div>

								<div class="bang_item_right_je">¥{{item.prizeAmount}}</div>
							</div>

						</div>
					</div>
					<div class="wsj_xob" v-if="oCPData.godOrderData.length == 0">
						<img src="~@/assets/images/img_021.png" alt="" >
						<div>暂无数据</div>
					</div>

				</div>

			</template>

			<template v-if="oCPData.bang.isBang">
				<div class="item_chudi" id="scroll" @scroll="listenBottomOut">
					<div class="bang_item" v-for="(item,index) in oCPData.redRData" :key="index" @click="toZhuye(item.roleId)">
						<div class="bang_item_left" style="width: auto">
							<div class="bang_item_left_tou">
								<div class="tou">
									<img :src="item.headPicture" alt="" v-if="item.headPicture">
									<img src="~@/assets/images/new/new_019.png" alt="" v-else>
								</div>
							</div>
							<div class="bang_item_left_name">{{item.roleName}}</div>
						</div>
						<div class="bang_item_right1">
							<div>
								<img src="~@/assets/images/new/new_005.png" alt="">
								<div class="font_box">
									<span style="font-size: 0.32rem;color: #E2335D;margin-right: 0.053rem">{{item.evenRedCount}}</span><span>连红</span>
								</div>
							</div>



						</div>
					</div>
					<div class="wsj_xob" v-if="oCPData.redRData.length == 0">
						<img src="~@/assets/images/img_021.png" alt="" >
						<div>暂无数据</div>
					</div>
				</div>


			</template>

		</div>

<!--		<div class="home_body">-->
<!--			<div class="home_redback&#45;&#45;receive" v-if="oCPData.oYCData.isAbleGet" @click="fGoRedPack('one')">-->
<!--				您有 <span>跟单红包</span> 待领取，赶紧抢！！-->
<!--				<img src="~@/assets/images/img_035.png" >-->
<!--			</div>-->
<!--			<div class="home_card" @click="fGoPath('football')">-->
<!--				<div class="home_card_left">-->
<!--					<div class="home_card_left_bar home_barA"></div>-->
<!--					<div class="home_card_left_describe">-->
<!--						<p class="home_card_left_describe_title">竞彩足球</p>-->
<!--						<p class="home_card_left_describe_detail">分享来自全世界的足球喜悦</p>-->
<!--					</div>-->
<!--				</div>-->
<!--				<img class="home_card_right" src="~@/assets/images/img_004.png">-->
<!--			</div>-->

<!--			<div class="home_card" @click="fGoPath('basketball')">-->
<!--				<div class="home_card_left">-->
<!--					<div class="home_card_left_bar home_barB"></div>-->
<!--					<div class="home_card_left_describe">-->
<!--						<p class="home_card_left_describe_title">竞彩篮球</p>-->
<!--						<p class="home_card_left_describe_detail">寰宇篮球世界尽在此刻</p>-->
<!--					</div>-->
<!--				</div>-->
<!--				<img class="home_card_right" src="~@/assets/images/img_005.png">-->
<!--			</div>-->

<!--			<div v-if="ENV === 'development'" class="home_card" @click="fGoPath('beijingball')">-->
<!--				<div class="home_card_left">-->
<!--					<div class="home_card_left_bar home_barD"></div>-->
<!--					<div class="home_card_left_describe">-->
<!--						<p class="home_card_left_describe_title">北京单场</p>-->
<!--						<p class="home_card_left_describe_detail">不一样的玩法不一样的机会</p>-->
<!--					</div>-->
<!--				</div>-->
<!--				<img class="home_card_right" src="~@/assets/images/img_036.png">-->
<!--			</div>-->

<!--			<div class="home_card" @click="fnJumpApp">-->
<!--				<div class="home_card_left">-->
<!--					<div class="home_card_left_bar home_barC"></div>-->
<!--					<div class="home_card_left_describe">-->
<!--						<p class="home_card_left_describe_title">大神推荐</p>-->
<!--						<p class="home_card_left_describe_detail">各路大神八方神单天降祥瑞</p>-->
<!--					</div>-->
<!--				</div>-->
<!--				<img class="home_card_right" src="~@/assets/images/img_006.png">-->
<!--			</div>-->

<!--			<div class="home_help" @click="fnGoGeneralTxt('helpCenter')">-->
<!--				<div class="home_help_left">-->
<!--					<van-icon class="home_help_left_bar" name="question-o" color="#B1CAF8" size="0.8533rem" />-->
<!--					<div class="home_help_left_describe">-->
<!--						<p class="home_help_left_describe_title">帮助中心</p>-->
<!--						<p class="home_help_left_describe_detail">提供您帮助与支持</p>-->
<!--					</div>-->
<!--				</div>-->
<!--				<img class="home_help_right" src="~@/assets/images/img_007.png">-->
<!--			</div>-->
<!--		</div>-->
		<Drag
			v-if="oCPData.oYCData.oDrag && (Number(oCPData.oYCData.oDrag.countUsable) > 0 || oCPData.oYCData.oDrag.countUsable === '99+')"
			:ball="oCPData.oYCData.oDrag" @click="fGoRedPack('zero')"></Drag>

		<h-dialog-a title="跟单信息" dialogKey="dialogA" :show="oDialogA.showDialog"
			:showCancelButton="oDialogA.showCancelButton" :confirmButtonText="oDialogA.confirmButtonText"
			@fBeforeClose="fBeforeClose">
			<div class="home_planInfo">
				<div class="home_planInfo_title">
					<div class="home_planInfo_title_border"></div>
					<span class="home_planInfo_title_a">{{oPlanInfo.planDetail.lotteryPlanType}}</span>
					<span class="home_planInfo_title_b">{{oPlanInfo.planDetail.lotteryPlanSubType}}</span>
					<span class="home_planInfo_title_d" v-if="oPlanInfo.planDetail.bonusOptimizedSign">奖金优化</span>
					<span class="home_planInfo_title_c"
						v-else-if="oPlanInfo.planDetail.mixTypeList.length <= 1">{{oPlanInfo.planDetail.mixTypeList[0]}}</span>
					<span class="home_planInfo_title_c" v-else>多串</span>
				</div>

				<div class="home_planInfo_user">
					<img class="home_planInfo_user_headPortrait"
						:src="oPlanInfo.roleDetail.head ? oPlanInfo.roleDetail.head : defaultAvatar" alt="">
					<div class="home_planInfo_user_info">
						<div class="home_planInfo_user_info_top">
							<span class="home_planInfo_user_info_top_name">{{oPlanInfo.roleDetail.roleName}}</span>
							<div class="home_planInfo_user_info_top_red" v-if="oPlanInfo.roleDetail.evenRedCount">
								<img class="home_planInfo_user_info_top_red_img" src="~@/assets/icon/icon-001.png"
									alt="">
								<em>{{oPlanInfo.roleDetail.evenRedCount}}</em><span>连红</span>
							</div>
						</div>
						<p class="home_planInfo_user_info_time">
							截止时间：{{fnFormatData(oPlanInfo.planDetail.stopCopy,"MM-dd hh:mm")}}</p>
					</div>
				</div>
			</div>
		</h-dialog-a>
		<h-dialog-a title="投注信息" dialogKey="dialogB" :show="oDialogB.showDialog"
			:showCancelButton="oDialogB.showCancelButton" :confirmButtonText="oDialogB.confirmButtonText"
			@fBeforeClose="fBeforeClose">
			<div class="home_betInfo">
				<div class="home_betInfo_title">
					<div class="home_betInfo_title_border" :class="oBetInfo.lotteryTypeEnum && OLOTTERYTYPE[oBetInfo.lotteryTypeEnum].bgcClass"></div>
					<span class="home_betInfo_title_a">{{fnLotteryType(oBetInfo.lotteryTypeEnum)}}</span>
					<span class="home_betInfo_title_b" v-if="oBetInfo.lotteryTypeEnum !== 'BJDC'">混合过关</span>
				</div>
				<div class="home_betInfo_time">
					截止时间：{{oBetInfo.expiredTime}}
				</div>
				<div class="home_betInfo_user">
					<span v-for="(item, index) in oBetInfo.mixTypeList" :key="index">{{item}}</span>
				</div>
			</div>
		</h-dialog-a>
		<img src="~@/assets/images/new/new_031.png" alt="" class="tix" v-if="!oCPData.isTiShow">
		<img src="~@/assets/images/new/new_039.png" alt="" class="tix1" @click="tixin" v-if="!oCPData.isTiShow">
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		nextTick,
		computed,
		// onMounted,
		// onUnmounted
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import {
		onBeforeRouteLeave,
		useRouter
	} from 'vue-router';
	import Drag from "@/components/Public/drag.vue";
	import {
		fnFormatData,
		fnJumpApp,
		uaFun
	} from "@/utils/public.js";
	import {
		fnLotteryType
	} from "@/utils/project.js";
	import {
		olotteryType as OLOTTERYTYPE
	} from "@/utils/options.js";
	import {
		fnGoGeneralTxt
	} from "@/utils/router.js";
	import {
		apiImgList,
		apiAnnouncementList,
		apiGodOrderList,
		apiQueryAllEvenRedCount,
		queryAllEvenRedRank
	} from '@/api/home';
	import {
		apiGetBetContent,
		apiGetPlatformPlanDetail
	} from '@/api/other';
	import {
		apiRedpackNum,
		apiHasGetable
	} from '@/api/redPage';
	import defaultAvatar from "@/assets/images/img_032.png";
	import Homebg from "@/assets/images/project/img_001.png";
	import HDialogA from "@/components/HDialog/HDialogA/index.vue";

	import Swiper, {
		Autoplay,
		EffectCoverflow,
	} from "swiper";
	Swiper.use([Autoplay, EffectCoverflow]);
	// swiper.less/sass/css 决定了基础的样式
	import "swiper/swiper.scss";
	import {Toast} from "vant";
	export default {
		name: "Home",
		components: {
			Drag,
			HDialogA
		},
		setup() {
			const isApp = uaFun()
			const router = useRouter();
			const store = useStore(); // 使用useStore方法
			const UserInfo = computed(() => { return store.state.userInfo.userInfo});
			const ENV = process.env.NODE_ENV;
			//离开当前的组件，触发
			onBeforeRouteLeave((to) => {
				if (to.path === "/beijingball") {
					to.meta.resetType = "1";
					return true
				}
			});

			// 当前页面数据
			const oCPData = reactive({
				isTiShow:false,
				dialogA: {
					showDialog: true,
					showCancelButton: true,
					confirmButtonText: "立即跟单"
				},
				oNCData: {
					aTab: [{
						title: "每日神单",
						name: "DAY"
					}, {
						title: "连红榜单",
						name: "RED"
					}],
					// list组件数据
					oComponentList: {
						loadingText: "加载中...",
						finishedText: "已无更多数据",
						errorText: "请求失败，点击重新加载"
					}
				},
				oYCData: {
					// 当前tabs类型，对应name
					tabsActive: "DAY",
					pageData: {
						aImages: [],
						aAnnouncement: [],
						aGodOrder: [],
					},
					// 是否已跟单并且未领完可领取的
					isAbleGet: false,
					oDrag: {
						countUsable: 0
					}
				},
				bang:{
					isBang:false,
					styleBang2:'font-size: 0.373rem;font-weight: 400',
					styleBang1:'',
				},
				godData:[],
				godOrderData:[],
				redRData:[],
				size:20,
				total:0

			});

			const bangFun = () =>{
				oCPData.bang.isBang = false
				oCPData.bang.styleBang2 = 'font-size: 0.373rem;font-weight: 400'
				oCPData.bang.styleBang1 = ''

			};
			const bangFun1 = () =>{
				oCPData.bang.isBang = true
				oCPData.bang.styleBang1 = 'font-size: 0.373rem;font-weight: 400'
				oCPData.bang.styleBang2 = ''
				// window.addEventListener("scroll", listenBottomOut,true);
				queryAllEvenRedRankFun()

			};
			// 获取图片列表
			const fGetImgList = () => {
				apiImgList().then(res => {
					oCPData.oYCData.pageData.aImages = res && res.result && res.result.length ? res.result : [{
							picUrl: Homebg
						}];
					nextTick(() => {
						fInitSwiper();
					});
				}).catch(() => {
					oCPData.oYCData.pageData.aImages = [{
						picUrl: Homebg
					}];
					nextTick(() => {
						fInitSwiper();
					});
				})
			};
			// 获取中奖公告列表
			const fGetAnnouncementList = () => {
				apiAnnouncementList().then(res => {
					let tData = res && res.result ? res.result : [];
					oCPData.oYCData.pageData.aAnnouncement = tData;
				})
			};
			//大神推荐
			const apiGodOrderListFun = () =>{
				apiGodOrderList({
					locationEnum:'HOME'
				}).then(res =>{
					console.log(res);
					oCPData.godData = res.result

				})
			};
			const toPlanDetail = (Id) =>{
				// 跳转到方案详情
				router.push({
					path: '/planDetail',
					query: {
						id: Id
					}
				})
			}
			const toZhuye = (Id) =>{
				// 跳转到方案详情
				router.push({
					path: '/zhuye',
					query: {
						id: Id
					}
				})
			}
			const toGodBd = () =>{
				// 跳转到方案详情
				router.push({
					path: '/godBd'
				})
			}
			//每日神单
			const apiQueryAllEvenRedCountFun = () =>{
				apiQueryAllEvenRedCount().then(res=>{
					oCPData.godOrderData = res.result
				})
			};
			//连红榜单
			const queryAllEvenRedRankFun = () =>{
				Toast({
					type: "loading"
				});
				queryAllEvenRedRank({
					"current": 1,
					"size": oCPData.size
				}).then(res=>{
					oCPData.redRData = res.result.records
					oCPData.total = res.result.total
				})
			};
			// 获取红包数量
			const fRedPack = () => {
				apiRedpackNum().then(res => {
					let tData = res && res.result ? res.result : 0;
					if (tData > 99) {
						tData = "99+"
					}
					oCPData.oYCData.oDrag.countUsable = tData;
				})
			};
			//页面加载
			// onMounted(() => {
			//
			// });
			//页面卸载
			// onUnmounted(() => {
			// 	window.removeEventListener("scroll", listenBottomOut,true);
			// });

			const listenBottomOut = () =>{

				//正文总高度
				// console.log(document.getElementById("scroll").scrollTop);
				let scrollHeight = document.getElementById("scroll").scrollHeight;
						//元素可见区域高度
						let offsetHeight = document.getElementById("scroll").offsetHeight;
						//可滚动容器超出当前窗口显示范围的高度
						let scrollTop = document.getElementById("scroll").scrollTop;
						//避免切换时读取到异常高度
						if (scrollTop == 0) {
						scrollHeight= 10000;
						}
						//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现scrollHeight< (offsetHeight+ scrollTop)的情况，严格来讲，是接近底部。
						//console.log(scrollHeight + " " + offsetHeight+ " " + scrollTop);

							// console.log("加载中~");
							if (scrollTop >= scrollHeight - offsetHeight) {
								if(oCPData.total != oCPData.redRData.length){
									//此处添加自定义操作
									oCPData.size += 20
									queryAllEvenRedRankFun()
								}

							}


			};
			// 是否存在已跟单可抢红包
			const fIsHasGetable = () => {
				apiHasGetable({
				}).then(res => {
					let tData = res && res.result ? res.result : false;
					oCPData.oYCData.isAbleGet = tData;
				})
			};

			// 前往路径
			const fGoPath = (path) => {
				router.push({
					path: '/' + path
				})
			};
			// 打开一个新页面
			const fOpenSwiperPath = (Index) => {
				const tDetailInfo = oCPData.oYCData.pageData.aImages[Index].detailInfo;
				if (tDetailInfo) {
					store.dispatch("other/USEDITOR", tDetailInfo);
					router.push({
						path: '/web',
						query: {
							type: "editor"
						}
					})
				}
			};

			// 跳转红包列表页面
			const fGoRedPack = (pType) => {
				console.log("跳转红包列表页面")
				router.push({
					path: '/redPackList',
					query: {
						type: pType
					}
				})
			};

			const fInitSwiper = () => {
				new Swiper(".home_swiper", {
					on:{
						click: function(){
							fOpenSwiperPath(this.realIndex)
						},
					},
					//循环
					loop: true,
					//每张播放时长3秒，自动播放
					spaceBetween: 16,
					// 切换效果
					effect: "coverflow",
					// 该选项给Swiper用户提供小小的贴心应用，设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。
					grabCursor: true,
					// 设定为true时，active slide会居中，而不是默认状态下的居左。
					centeredSlides: true,
					// 设置slider容器能够同时显示的slides数量(carousel模式)。
					slidesPerView: 1.32,
					// 启动动态检查器(OB/观众/观看者)，当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper。默认false，不开启，可以使用update()方法更新。
					observer: true,
					observeParents: true,
					observeSlideChildren: true,
					// 自动切换
					autoplay: {
						// 自动切换的时间间隔
						delay: 3000,
						// 如果设置为true，当切换到最后一个slide时停止自动切换
						stopOnLastSlide: false,
						// 用户操作swiper之后，是否禁止autoplay。默认为true：停止
						disableOnInteraction: false,
					},
					// 类似于苹果将多首歌曲的封面以3D界面的形式显示出来的方式
					coverflowEffect: {
						// slide做3d旋转时Y轴的旋转角度
						rotate: 0,
						// 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
						stretch: -70,
						// slide的位置深度。值越大z轴距离越远，看起来越小。
						depth: 500,
						// depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
						modifier: 1,
						// 是否开启slide阴影
						slideShadows: true,
					},
				});
			};




			const oDialogA = reactive({
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "立即跟单"
			});
			// 跟单方案信息
			const oPlanInfo = ref({
				planDetail: {},
				roleDetail: {}
			});
			const oDialogB = reactive({
				showDialog: false,
				showCancelButton: true,
				confirmButtonText: "立即付款"
			});
			// 投注信息
			const oBetInfo = ref({});
			const OPTId = computed(() => { return store.state.other.oPTId});
			// 获取剪切板内容
			const fGetClipboard = () => {
				// 如果平台ID不存在
				if (OPTId.value && OPTId.value.id && !OPTId.value.isUse) {
					let tPromise = null;
					let Type = "NOBET";
					let tText = OPTId.value.id;
					let tLogo = tText.substring(0, 3);
					if (tLogo === "BET") {
						Type = tLogo;
						tPromise = apiGetBetContent({
							key: tText
						});
					} else {
						tPromise = apiGetPlatformPlanDetail({
							currentRoleId: UserInfo.value.roleId,
							planId: tText
						});
					}
					tPromise.then(res => {
						store.dispatch("other/UOPTId", {
							id: tText,
							isUse: true
						});
						if (Type === "BET") {
							if (res && res.result) {
								let tData = res.result;
								let tBetInfo = JSON.parse(tData);
								oBetInfo.value = tBetInfo;
								oBetInfo.value.mixTypeList = tBetInfo.mixTypes.split("^");
								oDialogB.showDialog = true;
							}
						} else {
							if (res && res.result) {
								oPlanInfo.value.planDetail = res.result.planDetail ? res.result.planDetail :
								{};
								oPlanInfo.value.planDetail.mixTypeList = oPlanInfo.value.planDetail.mixTypes
									.split(
										"^");
								oPlanInfo.value.roleDetail = res.result.roleDetail ? res.result.roleDetail :
								{};
								oDialogA.showDialog = true;
							}
						}
					}).catch(() => {
						return false
					})
				} else {
					console.log("方案ID已使用")
				}
			};
			// 弹框关闭前
			const fBeforeClose = (action, oDialog, fn) => {
				const {
					dialogKey
				} = oDialog;
				if (dialogKey === "dialogA") {
					if (action === "confirm") {
						router.push({
							path: '/planDetail',
							query: {
								copyPlanId: oPlanInfo.value.planDetail.id
							}
						})
						// 跳转至跟单页面
						oDialogA.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oDialogA.showDialog = false;
						fn(false)
					}
				} else if (dialogKey === "dialogB") {
					if (action === "confirm") {
						let tBetInfo = oBetInfo.value;
						store.dispatch("allPlay/UBETSelected", tBetInfo);
						if (tBetInfo.lotteryTypeEnum === "JCZQ") {
							router.push({
								path: '/bet',
							});
						} else if (tBetInfo.lotteryTypeEnum === "BJDC") {
							router.push({
								path: '/betBD',
							});
						} else {
							router.push({
								path: '/betLQ',
							});
						}
						// 跳转至投注页面
						oDialogB.showDialog = false;
						fn(false)
					} else if (action === "cancel") {
						oDialogB.showDialog = false;
						fn(false)
					}
				}
			};
			const tixin = () =>{
				sessionStorage.setItem('isTiShow', true);
				oCPData.isTiShow = JSON.parse(sessionStorage.getItem('isTiShow'));
			}
			oCPData.isTiShow = JSON.parse(sessionStorage.getItem('isTiShow'));
			apiGodOrderListFun();
			apiQueryAllEvenRedCountFun();
			fRedPack();
			// jiant();
			fGetAnnouncementList()
			fIsHasGetable();
			fGetClipboard();
			fGetImgList();

			return {
				ENV,
				queryAllEvenRedRankFun,
				tixin,
				toPlanDetail,
				toZhuye,
				toGodBd,
				defaultAvatar,
				oCPData,
				fGoRedPack,
				listenBottomOut,
				fGoPath,
				bangFun,
				bangFun1,
				fOpenSwiperPath,
				fnJumpApp,
				isApp,
				oDialogA,
				oPlanInfo,

				oDialogB,
				oBetInfo,

				fBeforeClose,

				fnGoGeneralTxt,
				fnFormatData,
				fnLotteryType,

				OLOTTERYTYPE
			}
		},

	}
</script>

<style scoped lang="scss">
	.home {
		width: 100%;
		height: 100%;
		padding-top: 2rem;
		padding-bottom: 1.6rem;
		background: #F5F5F5;
		box-sizing: border-box;
		overflow: scroll;
		.top{
			width: 100%;
			height: 1.6rem;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 99999999;
			background: #F5F5F5;
			.top_search{
				width: calc(100% - 0.853rem);
				height: 0.907rem;
				position: absolute;
				left: 0.427rem;
				bottom: 0.133rem;
				background: #FFFFFF;
				box-shadow: 0px 0.107rem 0.427rem 0px rgba(199,202,216,0.2);
				border-radius: 0.453rem;
				display: flex;
				align-items: center;
				img{
					width: 0.373rem;
					height: 0.373rem;
					margin-left:  0.267rem;
				}
				div{
					margin-left: 0.133rem;
					color:  #D2D2D2;
				}
			}

		}
		.dashen{
			width: calc(100% - 0.64rem);
			margin: 0.427rem auto 0.427rem;
			background: #FAFBFF;
			box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);
			border-radius: 0.427rem;
			padding: 0.267rem;
			box-sizing: border-box;
			.dashen_bot{
				width: 100%;
				background: #FFFFFF;
				border-radius: 0.213rem;
				padding-top: 0.133rem;
				display: flex;
				flex-wrap: wrap;
				.wsj_xob{
					width: 100%;
					height: 100%;
					img{
						width: calc(100% - 2.72rem);
						height: auto;
						margin:0 1.36rem ;
					}
					div{
						width: 100%;
						height: 0.453rem;
						font-size: 0.32rem;
						font-weight: 400;
						color: #979797;
						line-height: 0.453rem;
						text-align: center;
					}
				}
				.dashen_item{
					width: 25%;
					display: flex;
					flex-direction: column;
					align-items: center;

					.dashen_item_box{
						width: 1.173rem;
						height: 1.173rem;
						margin: 0.24rem 0 0.107rem;
						.dashen_box{
							width: 1.173rem;
							height: 1.173rem;
							position: relative;
							img{
								width: 100%;
								height: 100%;
								border-radius: 0.587rem;
							}
							div{
								min-width: 0.427rem;
								height: 0.427rem;
								background: #FF7671;
								border-radius: 0.213rem;
								font-size: 0.32rem;
								font-weight: 500;
								line-height: 0.427rem;
								color: #FFFFFF;
								position: absolute;
								top: -0.107rem;
								right: -0.107rem;
								text-align: center;
								padding: 0 0.107rem;
								box-sizing: border-box;
							}
						}
					}
					.dashen_item_title{
						width: 100%;
						font-size: 0.32rem;
						font-weight: 400;
						color: #383838;
						line-height: 0.453rem;
						text-align: center;
						margin-bottom: 0.24rem;
					}

				}
			}
			.dashen_top{
				width: 100%;
				height: 0.8rem;
				display: flex;
				justify-content: space-between;
				margin-bottom: 0.107rem;
				.top_left{
					height: 100%;
					display: flex;
					img{
						width: 0.773rem;
						height: 0.8rem;
						margin-left: 0.107rem;
					}
					.title{
						margin-left: 0.213rem;
						height: 100%;
						font-size: 0.427rem;
						font-weight: 600;
						color: #383838;
						line-height: 0.8rem;
					}
				}
				.font{
					height: 100%;
					font-size: 0.373rem;
					font-weight: 400;
					color: #383838;
					line-height: 0.8rem;
				}
			}
		}

		.bangdan{
			width: calc(100% - 0.64rem);
			height: 10rem;
			background: #FAFBFF;
			box-shadow: 0px 0.053rem 0.427rem 0px rgba(224,228,246,0.5);
			border-radius: 0.32rem;
			margin: 0 auto;
			padding-bottom: 0.267rem;
			.bang_top{
				width: 100%;
				height: 1.147rem;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				.bang_title_box{
					/*width: 1.707rem;*/
					/*height: 100%;*/
					.bang_title{
						/*width: 1.707rem;*/
						height: 0.587rem;
						font-size: 0.427rem;
						font-weight: 600;
						color: #383838;
						line-height: 0.587rem;
						position: relative;
						img{
							width: 0.507rem;
							height: 0.16rem;
							position: absolute;
							left: calc((100% - 0.507rem)/2);
							bottom: -0.187rem;
						}
					}

				}
			}
			.bang_item_box,.item_chudi{
				overflow-y: auto;
				height: 8.853rem;
				.wsj_xob{
					width: 100%;
					height: 100%;
					img{
						width: calc(100% - 2.72rem);
						height: auto;
						margin: 2rem 1.36rem 0;
					}
					div{
						width: 100%;
						height: 0.453rem;
						font-size: 0.32rem;
						font-weight: 400;
						color: #979797;
						line-height: 0.453rem;
						text-align: center;
					}
				}
				.bang_item{
					width: calc(100% - 0.8rem);
					height: 1.52rem;
					background: #FFFFFF;
					box-shadow: 0px 0.053rem 0.213rem 0px rgba(224,228,246,0.5);
					border-radius: 0.213rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin: 0.4rem auto 0.32rem;
					.bang_item_left{
						display: flex;
						align-items: center;
						width: 1.013rem;
						.bang_item_left_tou{
							width: 1.013rem;
							height: 1.013rem;
							margin: 0 0.293rem 0 0.4rem;
							.tou{
								width: 1.013rem;
								height: 1.013rem;
								position: relative;
								img{
									width: 1.013rem;
									height: 1.013rem;
									border-radius: 0.507rem;
								}
								div{
									padding: 0 0.107rem;
									box-sizing: border-box;
									height: 0.427rem;
									background: #FF7671;
									border-radius: 0.213rem;
									line-height: 0.427rem;
									text-align: center;
									position: absolute;
									top: 0;
									right: 0;
									font-size: 0.32rem;
									font-weight: 500;
									color: #FFFFFF;
								}
							}
						}
					}
					.bang_item_right1{
						/*width: 1.067rem;*/
						height: 100%;
						padding-top: 0.32rem;
						box-sizing: border-box;
						margin-right: 0.267rem;
						div{
							height: 0.667rem;
							position: relative;
							img{
								width: 1.067rem;
								height: 0.667rem;
							}
							.font_box{
								width: 100%;
								height: 0.667rem;
								position: absolute;
								top: 0;
								right: 0;
								line-height: 0.973rem;
								text-align: center;
								span{
									font-size: 0.213rem;
									font-weight: 500;
									color: #383838;
								}
							}
						}

						/*height: 0.667rem;*/
					}
					.bang_item_right{
						width: calc(100% - 2.013rem);
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-right:0.267rem ;
						box-sizing: border-box;
						.bang_item_right_r_box{
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: flex-end;
							.bang_item_right_r{
								display: flex;
								align-items: flex-end;
								img{
									width: 0.8rem;
									height: 0.8rem;
									margin-right: 0.213rem;
								}
								.bang_item_right_title{
									font-size: 0.32rem;
									font-weight: 600;
									color: #383838;
									line-height: 0.453rem;
								}
							}
							.bang_item_right_je{
								font-size: 0.373rem;
								font-weight: bold;
								color: #E2335D;
								line-height: 0.427rem;
							}
						}



					}
				}
			}

		}
		.home_swipe_announcement {
			height: 0.7733rem;
			display: flex;
			align-items: center;
			padding: 0.1066rem 0.5333rem;
			margin: 0.32rem ;
			background: linear-gradient(315deg, #E5C798 0%, #F1D68F 100%);
			border-radius: 0.16rem 0.16rem 0.2133rem 0.2133rem;
			box-sizing: border-box;

			&_l {
				width: 0.48rem;
				height: 0.4rem;
				margin-right: 0.5333rem;
			}

			&_r {
				flex: 1;
				height: 0.5333rem;

				&_li {
					text-align: center;
					font-size: 0.3733rem;
					font-weight: 400;
					color: #9E611E;
					line-height: 0.5333rem;
				}
			}
		}

		.swiper-slide {
			width: 7.4666rem;
			height: 4.1066rem;
			background: #FFFFFF;
			border-radius: 0.4266rem;
			opacity: 0.53;
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		.swiper-slide-active {
			opacity: 1;
		}

		.home_body {
			padding: 1.0666rem 0.32rem 0;
			background-image: url(~@/assets/images/img_003.png);
			background-size: 100% 3.0666rem;
			background-repeat: no-repeat;



			.home_redback--receive {
				position: relative;
				display: flex;
				align-items: center;
				padding: 0 0.4266rem;
				margin-bottom: 0.5866rem;

				width: 7.84rem;
				height: 1.1733rem;

				background: linear-gradient(90deg, #FF581E 0%, #F0B5A8 100%);
				box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.5866rem;

				font-size: 0.3733rem;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.5333rem;

				&>span {
					padding: 0.0266rem 0.16rem;
					margin: 0 0.1333rem;
					background: #FED530;
					border-radius: 0.16rem;

					font-size: 16px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #383838;
					line-height: 22px;
				}
				&>img {
					width: 2.9866rem;
					height: 2.3466rem;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(30%, -50%);
				}
			}

			.home_card {
				background: linear-gradient(270deg, rgba(250, 251, 255, 0) 0%, #FDFDFF 100%);
				box-shadow: 0px 0.0533rem 0.4266rem 0px rgba(224, 228, 246, 0.5);
				border-radius: 0.4266rem;
				margin-bottom: 0.32rem;

				display: flex;
				justify-content: space-between;
				align-items: center;

				.home_card_left {
					flex: 1;

					display: flex;
					align-items: center;

					.home_barA {
						background: linear-gradient(315deg, #3D66E6 0%, #01B6FF 100%);
					}

					.home_barB {
						background: linear-gradient(296deg, #F21497 0%, #FF9EFF 100%);
					}

					.home_barC {
						background: linear-gradient(315deg, #FB8901 0%, #FFD543 100%);
					}

					.home_barD {
						background: linear-gradient(180deg, #DA76FF 0%, #8E03C2 100%);
					}
					.home_card_left_bar {
						width: 0.16rem;
						height: 0.9066rem;
						border-radius: 0.2133rem;
						margin: 0 0.32rem 0 0.2133rem;
					}

					.home_card_left_describe {
						.home_card_left_describe_title {
							font-size: 0.48rem;
							font-weight: 600;
							color: #383838;
							line-height: 0.6666rem;
						}

						.home_card_left_describe_detail {
							font-size: 0.32rem;
							font-weight: 400;
							color: #979797;
							line-height: 0.4533rem;
						}
					}
				}

				.home_card_right {
					width: auto;
					height: 2.4rem;
				}
			}


			.home_help {
				padding: 0.0533rem 0.8rem 0.0533rem 0.64rem;
				background: rgba(255, 255, 255, 0.25);
				border-radius: 1.2rem;
				border: 0.0266rem solid #FAFBFC;
				margin-top: 0.64rem;

				display: flex;
				justify-content: space-between;
				align-items: center;

				.home_help_left {
					flex: 1;

					display: flex;
					align-items: center;

					.home_help_left_bar {
						margin-right: 0.3733rem;
					}

					.home_help_left_describe {
						.home_help_left_describe_title {
							font-size: 0.4rem;
							font-weight: 600;
							color: #5973A5;
							line-height: 0.56rem;
						}

						.home_help_left_describe_detail {
							font-size: 0.32rem;
							font-weight: 400;
							color: #B1CAF8;
							line-height: 0.4533rem;
						}
					}
				}

				.home_help_right {
					width: auto;
					height: 1.76rem;
				}
			}
		}
	}
	.yiImg{
		width: 7.4666rem;
		height: 4.1066rem;
		border-radius: 0.4266rem;
		margin: 0 auto;
	}
	.tix{
		width: 7.56rem;
		height: 1.76rem;
		position: fixed;
		left: calc((100% - 7.56rem)/2);
		bottom: 1.467rem;
		z-index: 99999;
	}
	.tix1{
		width: 0.267rem;
		height: 0.267rem;
		position: fixed;
		right:calc(((100% - 7.56rem)/2) + 0.633rem);
		bottom: 2.241rem;
		z-index: 999999;
	}
</style>
