import request from '@/utils/request';
import consts from '@/utils/consts';


// 轮播图获取
export function apiImgList(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/showHomePicture',
		method: 'post',
		data: data
	})
}
// 中奖轮播公告
export function apiAnnouncementList(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/getPrizeAnnouncement',
		method: 'post',
		data: data
	})
}

// 大神推荐
export function apiGodOrderList(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/appListRecommendGod',
		method: 'post',
		data: data
	})
}
// 首页每日神单
export function apiQueryAllEvenRedCount(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/getPerfectPlanOrder',
		method: 'post',
		data: data
	})
}
// 首页连红榜单
export function queryAllEvenRedRank(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/queryAllEvenRedRank',
		method: 'post',
		data: data
	})
}
// 大神榜单
export function godsRank(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/godsRank',
		method: 'post',
		data: data
	})
}
// 搜索用户
export function getDetailByName(data) {
	return request({
		url: consts.businessAnonymous + '/homeInfo/getDetailByName',
		method: 'post',
		data: data
	})
}
// 获取首页红包
export function countUsable(data) {
	return request({
		url: consts.businessAnonymous + '/redpack/countUsable',
		method: 'post',
		data: data
	})
}
//个人主页信息
export function getHomeStatistic(data) {
	return request({
		url: consts.businessAnonymous + '/plan/getHomeStatistic',
		method: 'post',
		data: data
	})
}
//最近三个月中奖记录
export function getThreeMonthsBetRecord(data) {
	return request({
		url: consts.businessAnonymous + '/plan/getThreeMonthsBetRecord',
		method: 'post',
		data: data
	})
}
//最近七天中奖记录
export function getWeekBetRecord(data) {
	return request({
		url: consts.businessAnonymous + '/plan/getWeekBetRecord',
		method: 'post',
		data: data
	})
}
//关注
export function focusOn(data) {
	return request({
		url: consts.businessPublic + '/focus/focusOn',
		method: 'post',
		data: data
	})
}
//关注
export function focusCancel(data) {
	return request({
		url: consts.businessPublic + '/focus/focusCancel',
		method: 'post',
		data: data
	})
}
//粉丝
export function queryMemberFocusInfos(data) {
	return request({
		url: consts.businessPublic + '/focus/queryMemberFocusInfos',
		method: 'post',
		data: data
	})
}
//关注
export function queryMemberFocusInfosForPage(data) {
	return request({
		url: consts.businessPublic + '/focus/queryMemberFocusInfosForPage',
		method: 'post',
		data: data
	})
}

//点赞列表
export function pageAgree(data) {
	return request({
		url: consts.businessAnonymous + '/communication/pageAgree',
		method: 'post',
		data: data
	})
}


